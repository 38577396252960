.report-item-header {
  padding: 15px 20px 15px 20px;
  margin-bottom: 0;

  .card-body {
    margin-bottom: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .left-card-body {
    display: flex;
    align-items: center;
    cursor: pointer;

    .go-back-arrow {
      font-size: 27px;
      font-weight: bold;
      color: black;
    }
  }

  //.right-card-body{
  //  button{
  //    &:hover{
  //      background-color: unset !important;
  //    }
  //  }
  //}
}
