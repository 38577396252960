.MuiDialog-paper,
.MuiDialogContent-root {
  overflow-y: visible !important;
}

.send-email-export {
  .MuiListItem-gutters {
    padding: 0 !important;
  }

  .MuiChip-root {
    background-color: #e0e0e070 !important;
  }
}
