/* -- Variables */
:root {
  /* -- Decorative assets */
  --table-item-border-radius: 16px;
  --table-border-color: #e8e8e8;
  --table-border-width: 2px;
  /* -- Spacing */
  --table-spacing-sm: 5px;
  --table-spacing-md: 5px;
  --table-spacing-lg: 15px;
  --table-spacing-xl: 20px;
  /* -- Colors */
  --table-text-color: #414d66;
  --table-surface-gray-dark: #414d66;
  --table-surface-gray: #f0f2f5;
}
/* -- Table Base Styles */
.sm__report-table {
  // border: var(--table-border-width) solid var(--table-border-color);
}

.weather-widget-table {
  overflow-x: scroll;
  margin-bottom: 20px;
  background-color: #424e66;
  color: white;
}

.sm__report-table img {
  width: 100px;
}
.sm__report-table--title {
  color: white;
  text-align: center;
  padding: var(--table-spacing-md);
  background-color: var(--table-surface-gray-dark);
}
.sm__report-table--headers th {
  padding: var(--table-spacing-sm);
  background-color: var(--table-surface-gray);
  text-align: center;
  color: #424e66;
  border-bottom: var(--table-border-width) solid var(--table-border-color);
}
.sm__report-table--row td {
  padding: var(--table-spacing-md);
  text-align: center;
}

/* -- Compass */
.sm__table-compass-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: var(--table-border-width) solid var(--table-border-color);
  margin-bottom: var(--table-spacing-md);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.sm__table-compass__text {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  z-index: 1;
}
.sm__table-compass__text--direction {
  display: block;
  font-size: 0.7rem;
  text-align: center;
  margin-top: var(--table-spacing-md);
  /* margin-bottom: calc(-1 * var(--table-spacing-sm)); */
}

.sm__table-compass__text--speed {
  font-size: 1rem;
}

.sm__table-compass__arrow--wrapper {
  height: 100%;
  position: absolute;
  width: 20px;
  left: 20px;
  z-index: 0;
}
/* Compass Arrow directions */
/* -- NORTH */

.nne {
  transform: rotate(22.5deg);
}
.ne {
  transform: rotate(45deg);
}
.ene {
  transform: rotate(67.5deg);
}
.e {
  transform: rotate(90deg);
}
.ese {
  transform: rotate(112.5deg);
}
.se {
  transform: rotate(135deg);
}
.sse {
  transform: rotate(157.5deg);
}
.s {
  transform: rotate(180deg);
}
.ssw {
  transform: rotate(202.5deg);
}
.sw {
  transform: rotate(-135deg);
}
.wsw {
  transform: rotate(-114.5deg);
}
.w {
  transform: rotate(-90deg);
}
.wnw {
  transform: rotate(-69.5deg);
}
.nw {
  transform: rotate(-45deg);
}
.nnw {
  transform: rotate(-24.5deg);
}

.sm__table-compass__arrow {
  text-align: center;
  margin-top: calc(-2 * var(--table-spacing-md));
  z-index: 1;
}
/* -- Icon Styles */
.fa-sun {
  color: #ff8833;
}
/* Examples */
.ex {
  padding: var(--table-spacing-xl);
  box-sizing: border-box;
}
/* -- Compass */
.ex-compass {
  margin-top: var(--table-spacing-xl);
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 25px;
  padding: var(--table-spacing-lg);
  box-sizing: border-box;
}
.ex-compass .sm__table-compass__text--direction {
  margin-top: 3.5px;
  margin-bottom: calc(-1 * var(--table-spacing-sm));
}
.code {
  /* display: block; */
  padding: var(--table-spacing-sm);
  background-color: var(--table-surface-gray);
  width: max-content;
}
