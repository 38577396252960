
.empty-row {
  background-color: rgb(204, 204, 204);
}

.conwidth {
  width: 50px;
}

.table-margin {
  margin-bottom: 3rem;
}

//.ant-table-tbody > tr > td {
//  padding: 4px 4px !important;
//}

.reportTable {
  width: 100%;
  background: #fff;
  table{
    table-layout: auto !important;
  }
  //border-top: 1px solid $border-table-color;
  //border-left: 1px solid $border-table-color;
  //border-right: 1px solid $border-table-color;
}

//.ant-card-body{
//  background-color: $main-header-bg;
//  height: 100%;
//}

.paginator {
  float: right;
  margin-top: 10px;
}

.hide {
  display: none !important;
}

.even {
  background-color: #e4e1e1 !important;
}

.ant-table-row-level-1 {
  background-color: #f0f2f5 !important;
}
