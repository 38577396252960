@import "../global/mixin";
@import "../global/variables";

.card-presentational{
  width: 230px;
  margin-right: 30px;
  cursor: pointer;
}

.jr-card-header-color{
  min-height: 130px;
  display: flex;
  margin: 0;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.bg-gradient-active {
  //@include gradient-background-image($success, lighten($success, 16%), 0deg , '../../images/img.png');
  @include gradient-directional($success, lighten($success, 16%), 0deg);
}

.bg-gradient-inactive {
  @include gradient-directional($light-gray, lighten($light-gray, 16%), 0deg);
  //@include gradient-background-image($light-gray, lighten($light-gray, 16%), 0deg , '../../images/img.png');
}

