.filters-components{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  .MuiFormGroup-root{
    display: block !important;
  }

  .filter-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }


  .worker-filter{
    .MuiFormControl-root{
      vertical-align: baseline !important;
    }
  }
}